const skillsExperience = [
  "Data Science",
  "Machine Learning",
  "Artificial Intelligence",
  "Python",
  "JavaScript",
  "Panda",
  "Numpy",
  "Data Analysis",
  "R Studio",
  "Predictive Modeling",
  "Forecasting",
  "Time Series",
  "TensorFlow",
  "Keras",
  "ReactJS",
  "HTML",
  "JavaScript",
  "NodeJS",
  "CSS",
  "Java",
  "C++",
  "C",
  "DBMS",
  "MongoDB",
  "SQL",
  "Firebase",
  "NoSQL",
  "Linux",
  "AI/ML",
  "Anaconda",
  "Jupyter",
  "Stock Market Prediction",
];

export default skillsExperience;
